@import "../../../styles/helpers";

.section {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 120px;
    padding-bottom: 100px;
    @include m {
        display: block;
        min-height: auto;
        padding-top: 30px; } }

.wrap {
    position: relative;
    z-index: 3;
    max-width: 550px;
    @include t {
        max-width: 360px; }
    @include m {
        max-width: unset;
        text-align: left; } }

.wrapright {
    position: relative;
    z-index: 3;
    max-width: 550px;
    float: right;
    @include t {
        max-width: 360px; }
    @include m {
        max-width: unset;
        text-align: left;
        float: unset; } }

.title {
    margin-bottom: 32px; }

.text {
    margin-bottom: 20px;
    font-family: "Poppins",sans-serif;
    font-size: 16px!important;
    line-height: 1.8!important;
    font-size: 24px;
    line-height: 1.33333;
    letter-spacing: -.01em;
    color: #777e90; }

.gallery {
    position: absolute;
    top: 45%;
    right: calc(50% - 590px);
    width: 500px;
    transform: translateY(-40%);
    pointer-events: none;
    @include d {
        right: calc(50% - 440px);
        width: 390px;
        transform: translateY(-44%); }
    @include t {
        right: calc(50% - 380px); }
    @include m {
        position: relative;
        top: auto;
        right: auto;
        margin: auto;
        transform: translateY(0); }
    @media only screen and (max-width: "410px") {
        left: -38px; }
    @media only screen and (max-width: "370px") {
        left: -48px; } }

.galleryleft {
    position: absolute;
    top: 20%;
    left: calc(50% - 590px);
    width: 500px;
    transform: translateY(-40%);
    pointer-events: none;
    @include d {
        left: calc(50% - 440px);
        width: 390px;
        transform: translateY(-44%); }
    @include t {
        left: calc(50% - 380px); }
    @include m {
        position: relative;
        top: auto;
        left: auto;
        margin: auto;
        transform: translateY(0); }
    @media only screen and (max-width: "410px") {
        rigth: -38px; }
    @media only screen and (max-width: "370px") {
        rigth: -48px; } }

.preview {
    &:first-child {
        position: relative;
        z-index: 2; }
    &:nth-child(2) {
        top: -15%;
        left: 8%;
        z-index: 3;
        width: 33%; }
    &:nth-child(3) {
        top: -45%;
        right: -35%;
        z-index: 1;
        width: 90%; }
    &:not(:first-child) {
        position: absolute; }
    img {
        width: 100%; } }

.btnr {
    border-radius: 4px;
    cursor: pointer; }

.item {
    position: relative;
    padding-left: 36px;
    font-weight: 600;
    color: $neutrals4;
    &:before {
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        width: 24px;
        height: 24px;
        background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12L10 16L18 8' stroke='%2345B36B' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50% 50% / 100% auto; }
    &:not(:last-child) {
        margin-bottom: 16px; } }
