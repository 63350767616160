@import "../../../styles/helpers";

.section {
    text-align: center;
    img {
        width: 100%; } }
.sbg {
    padding: 0;
    margin: 0; }

.sbtn {
    margin-bottom: 100px; }

.stage {
    margin-bottom: 16px; }

.title {
    max-width: 650px;
    margin: 0 auto 30px;
    @include t {
        max-width: 550px; } }

.text {
    max-width: 450px;
    margin: 0 auto 48px;
    color: $neutrals4; }

.btnr {
    border-radius: 4px;
    cursor: pointer; }

.stage {
    margin-top: 40px;
    margin-bottom: 10px;
    color: $neutrals4; }
