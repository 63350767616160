@import "../../../styles/helpers";

.breadcrumbs {
    margin-bottom: 15px;
    @include d {
        margin-bottom: 64; }
    @include m {
        margin-bottom: 32px; } }

.wrap {
    border-radius: 24px;
    text-align: left;
    background: url("https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/insuranced.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    @include m {
        display: block;
        border-radius: 0px;
        text-align: center;
        background-size: unset;
        background-position: center; }
    @include t {
        border-radius: 0px;
        background-size: unset;
        background-position: center; }
    @include dark {
        background: $neutrals3; } }

.warpinner {
    height: 400px; }

.title {
    margin-bottom: 12px;
    font-size: 40px;
    @include m {
        font-size: 32px; }
    @include t {
        font-size: 32px; } }

.info {
    margin-bottom: 60px;
    font-size: 14px;
    line-height: 1.33333;
    letter-spacing: -.01em;
    color: #777E90;
    @include m {
        font-size: 14px;
        margin-bottom: 20px; } }

.imgsupport {
    @include m {
        display: none; } }
.list {
    margin-left: -34px;
    @include m {
        margin-left: -20px; } }

.item {
    display: inline;
    margin-left: 34px;
    @include button-2;
    color: $neutrals4;
    @include m {
        margin-left: 20px; }
    &:not(:last-child) {
        &:after {
            content: "";
            display: inline-block;
            width: 6px;
            height: 10px;
            margin-left: 34px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.293.793a1 1 0 0 0 0 1.414L3.086 5 .293 7.793a1 1 0 1 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
            @include m {
                margin-left: 20px; } } } }

.link {
    color: $neutrals4;
    transition: color .2s;
    &:hover {
        color: $blue; } }
