@import "../../styles/helpers";

.inner {
    overflow: hidden; }

.txttick11 {
    background: #ff5722;
    background: linear-gradient(to right, #ff5722 0%, #70250A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
