@import "../../styles/helpers";

.section {
    text-align: center;
    background: url(https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/wbottom.png);
    min-height: 650px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }

.stage {
    margin-bottom: 16px; }

.title {
    max-width: 650px;
    margin: 0 auto 32px;
    @include t {
        max-width: 550px; } }

.text {
    max-width: 450px;
    margin: 0 auto 48px;
    color: $neutrals4; }

.btnr {
    border-radius: 4px;
    cursor: pointer; }

