@import "../../../styles/helpers";

.breadcrumbs {
    margin-bottom: 15px;
    @include d {
        margin-bottom: 64; }
    @include m {
        margin-bottom: 32px; } }

.btnjoin {
    background-image: linear-gradient(90deg,#ff6b3e 0,#fa6806 50%,#ff5722);
    background-size: 300% 300%;
    border-radius: 10px;
    border-style: hidden;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: 700;
    margin: 10px 0;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    transition: .5s;
    width: 100%; }

.btnjoin:hover {
    background-position: right center; }

.wrap {
    padding: 30px 30px 30px 64px;
    border-radius: 24px;
    background: $neutrals6;
    text-align: left;
    background-color: #fff1f1;
    color: #000;
    display: flex;
    @include m {
        display: block;
        padding: 40px 20px;
        border-radius: 0px;
        text-align: center; }
    @include t {
        border-radius: 0px; }
    @include dark {
        background: $neutrals3; } }

.warpleft {
    display: flex;
    flex: 0 0 50%;
    padding-top: 4%;
    @include m {
        display: block;
        padding-top: 0; }
    @include t; }


.warpright {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-end; }

.title {
    margin-bottom: 12px;
    font-size: 40px;
    @include m {
        font-size: 32px; }
    @include t {
        font-size: 32px; } }

.info {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.33333;
    letter-spacing: -.01em;
    color: #777E90;
    @include m {
        font-size: 14px;
        margin-bottom: 20px; } }

.imgsupport {
    @include m {
        display: none; } }
.list {
    margin-left: -34px;
    @include m {
        margin-left: -20px; } }

.item {
    display: inline;
    margin-left: 34px;
    @include button-2;
    color: $neutrals4;
    @include m {
        margin-left: 20px; }
    &:not(:last-child) {
        &:after {
            content: "";
            display: inline-block;
            width: 6px;
            height: 10px;
            margin-left: 34px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.293.793a1 1 0 0 0 0 1.414L3.086 5 .293 7.793a1 1 0 1 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
            @include m {
                margin-left: 20px; } } } }

.link {
    color: $neutrals4;
    transition: color .2s;
    &:hover {
        color: $blue; } }
