@import "../../../styles/helpers";

.container {
    @include m {
        padding: 0; } }

.section {
    position: relative;
    display: flex;
    align-items: center;

    background: url(https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/wheader.png);
    min-height: 650px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 650px;
    padding: 20px 0px 0px 0px;
    @include d {
        min-height: 500px; }
    @include t {
        background-position: unset; } }

.sectionbillpay {
    position: relative;
    display: flex;
    align-items: center;

    background: url(https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/sub-header.png);
    min-height: 650px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 650px;
    padding: 20px 0px 0px 0px;
    @include d {
        min-height: 500px; } }

.sectionshopping {
    position: relative;
    display: flex;
    align-items: center;

    background: url(https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/shoppingh.png);
    min-height: 650px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 650px;
    padding: 20px 0px 0px 0px;
    @include d {
        min-height: 500px; } }

.sectioninsurance {
    position: relative;
    display: flex;
    align-items: center;

    background: url(https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/insuranceb.png);
    min-height: 650px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 650px;
    padding: 20px 0px 0px 0px;
    @include d {
        min-height: 500px; } }

.wrap {
    position: relative;
    z-index: 3;
    max-width: 550px;
    @include d {
        max-width: 530px;
        padding: 0 32px; } }

video {
    height: 430px;
    @include x {
        margin-top: 0px;
        height: 330px; }
    @include d {
        margin-top: 50px;
        height: 230px; }
    @include m {
        margin-top: 0px;
        height: 200px;
        width: 100%; } }

.title {
    margin-bottom: 32px; }

.infotext {
    margin-bottom: 32px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px !important;
    line-height: 1.8 !important;
    @include body-1;
    color: $neutrals4; }


.item {
    position: relative;
    padding-left: 36px;
    font-weight: 600;
    color: $neutrals4;
    &:before {
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        width: 24px;
        height: 24px;
        background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12L10 16L18 8' stroke='%2345B36B' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50% 50% / 100% auto; }
    &:not(:last-child) {
        margin-bottom: 16px; } }

.btns {
    display: flex; }

.button {
    @include m {
        padding: 0 20px; }
    &:not(:last-child) {
        margin-right: 12px; } }

.gallery {
    position: absolute;
    right: 0;
    pointer-events: none;
    @include m {
        position: relative;
        top: auto;
        right: auto;
        width: 100%;
        margin: 0 auto 42px;
        transform: translateY(0); } }

.preview {
    &:first-child {
        position: relative;
        z-index: 1; }
    &:nth-child(2) {
        top: 10%;
        left: 47%;
        z-index: 2;
        width: 13.5%; }
    &:nth-child(3) {
        top: 32%;
        right: 2.5%;
        z-index: 2;
        width: 14.5%; }
    &:not(:first-child) {
        position: absolute; }
    img {
        width: 100%; }
    @include m {
        video {
            width: 100%; } } }
