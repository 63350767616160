@import "../../../styles/helpers";

.breadcrumbs {
    margin-bottom: 32px;
    @include d {
        margin-bottom: 32px; }
    @include m {
        margin-bottom: 32px; } }

.wrap {
    padding: 50px;
    padding-bottom: 35px;
    border-radius: 24px;
    text-align: center;
    color: white;
    background-position: center;
    @include t {
        padding: 20px;
        border-radius: 0px; }
    @include dark {
        background: $neutrals3; } }

.title {
    margin-bottom: 30px;
    font-size: 16px;
    color: #6b6b6b; }

.subtitle {
    margin-bottom: 10px;
    font-size: 16px;
    color: #777E90; }

.htitle {
    margin-top: 50px;
    margin-bottom: 30px;
    color: #000000; }

.info {
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 1.33333;
    letter-spacing: -.01em;
    color: #bcbfc6;
    @include m {
        font-size: 14px; } }

.list {
    margin-left: -34px;
    @include m {
        margin-left: -20px; } }

.item {
    display: inline;
    margin-left: 34px;
    @include button-2;
    color: $neutrals4;
    @include m {
        margin-left: 20px; }
    &:not(:last-child) {
        &:after {
            content: "";
            display: inline-block;
            width: 6px;
            height: 10px;
            margin-left: 34px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.293.793a1 1 0 0 0 0 1.414L3.086 5 .293 7.793a1 1 0 1 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
            @include m {
                margin-left: 20px; } } } }

.link {
    color: $neutrals4;
    transition: color .2s;
    &:hover {
        color: $blue; } }


.billdriveresponsive {
    width: 100%;
    overflow-x: auto;
    scroll-snap-type: x mandatory; }


.billdriveresponsive::-webkit-scrollbar {
    height: 10px; }

.billdriveresponsive:hover {
    cursor: pointer;
    &::-webkit-scrollbar-track {
        background-color: #ffffff;
        border-radius: 100px; }
    &::-webkit-scrollbar-thumb {
        background-color: #f9e8e8;
        border-radius: 100px; } }


.billdrivetabs {
    display: table;
    border-collapse: separate;
    table-layout: auto;
    padding-bottom: 10px;

    .billdrivetab {
        scroll-snap-align: start;
        text-decoration: none!important;
        text-align: center;
        position: relative;
        display: table-cell;
        transition: all ease .3s;
        padding: 1em 1.6em;
        transform: translate3d(0, 0, 0);
        color: #a9a9a9;
        white-space: nowrap;
        cursor: pointer;
        min-width: 180px;
        &:hover {
            color: #ff5722;
            .category {
                transform: scale(1.1); } }

        &:after {
            transition: all .3s cubic-bezier(1, 0, 0, 1);
            will-change: transform, box-shadow, opacity;
            position: absolute;
            content: '';
            height: 3px;
            bottom: 8px;
            left: 0px;
            right: 0px;
            border-radius: 3px 3px 0px 0px;
            background: #eb6d0d;
            box-shadow: 0px 4px 10px 3px rgba(#eb6d0d, .15);
            opacity: 0;
            transform: scale(0, 1);
            width: 75%;
            margin: 0 auto; }

        &.active {
            color: #ff5722;
            &:after {
                opacity: 1;
                transform: scale(1, 1); } } } }

.pic {
    width: 45px; }

.category {
    border-radius: 50px;
    padding: 8px;
    background-color: #fff1f1;
    width: 63px;
    height: 63px;
    margin: 0 auto;
    transition: all ease .3s; }

.categoryname {
    margin-top: 15px;
    font-size: 14px; }

