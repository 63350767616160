[class^="play"] {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    width: 80px;
    height: 80px;
    transform: translate(-50%,-50%);
    box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.2);
    border-radius: 50%;
    background: $neutrals8;
    @include m {
        width: 48px;
        height: 48px; }
    svg {
        fill: $neutrals4;
        transition: all .2s;
        @include m {
            width: 12px;
            height: 12px; } }
    &:hover {
        svg {
            transform: scale(1.3);
            fill: $blue; } } }

.play-small {
    width: 48px;
    height: 48px; }
