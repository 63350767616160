@import "../../../styles/helpers";

.containerhead {
    text-align: center;
    margin-bottom: 40px; }

.roman {
    list-style-type: unset;
    margin-left: 40px; }

.containerpoints {
    margin-bottom: 40px; }

.romancircle {
    list-style-type: circle;
    margin-left: 40px; }

.romandecimal {
    list-style-type: decimal;
    margin-left: 40px; }

.sectionprivacy {
    padding: 50px 0; }
.top {
    margin-bottom: 80px;
    @include m {
        margin-bottom: 48px; } }

.title {
    margin-bottom: 20px; }

.tophead {
    margin-bottom: 80px;
    @include m {
        margin-bottom: 48px; } }

.infohead {
    margin-bottom: 20px;
    color: $neutrals3;
    @include m {
        margin-bottom: 48px; }
    @include dark {
        color: $neutrals6; }
    a {
        font-weight: 600;
        color: $red;
        transition: opacity .2s;
        &:hover {
            opacity: .8; } } }

.info {
    margin-bottom: 20px;
    color: $neutrals3;
    @include m {
        margin-bottom: 48px; }
    @include dark {
        color: $neutrals6; }
    a {
        font-weight: 600;
        color: $red;
        transition: opacity .2s;
        &:hover {
            opacity: .8; } } }

.nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -12px -6px 0;
    @include m {
        display: none; } }

.btn {
    margin: 12px 6px 0;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals8; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } } }

.dropdown {
    display: none;
    text-align: left;
    @include m {
        display: block; } }

.list {
    margin-bottom: -32px; }
