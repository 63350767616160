@import "../../../styles/helpers";

.section {
    text-align: center;
    background: url(https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/bills.png);
    min-height: 570px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    img {
        width: 100%; } }

.videopbody {
    line-height: 0; }
.videop {
    width: 100%!important;
    height: auto; }
.sbg {
    padding: 0;
    margin: 0; }

.sbtn {
    margin-bottom: 50px; }

.stage {
    margin-bottom: 16px; }

.title {
    max-width: 650px;
    margin: 0 auto 32px;
    @include t {
        max-width: 550px; } }

.text {
    max-width: 450px;
    margin: 0 auto 48px;
    color: $neutrals4; }

.btnr {
    border-radius: 4px;
    cursor: pointer; }
