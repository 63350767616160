[class^="section"] {
    padding: 70px 0 136px 0;
    @include m {
        padding: 64px 0; } }

.section-bg {
    background: $neutrals7;
    @include dark-common {
        background: $neutrals2; } }

.section-border-top {
    border-top: 1px solid $neutrals6;
    @include dark-common {
        border-color: $neutrals3; } }

.section-pb {
    padding-top: 0;
    @include d {
        padding-top: 0; }
    @include m {
        padding-top: 0; } }

.section-pb64 {
    padding: 0 0 64px;
    @include d {
        padding: 0 0 64px; } }
