@import "../../../styles/helpers";

.top {
    margin-bottom: 80px;
    text-align: center;
    @include m {
        margin-bottom: 48px; } }

.title {
    margin-bottom: 20px; }

.info {
    max-width: 650px;
    margin: 0 auto 80px;
    color: $neutrals3;
    @include m {
        margin-bottom: 48px; }
    @include dark {
        color: $neutrals6; }
    a {
        font-weight: 600;
        color: $red;
        transition: opacity .2s;
        &:hover {
            opacity: .8; } } }

.nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -12px -6px 0;
    @include m {
        display: none; } }

.btn {
    margin: 12px 6px 0;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals8; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } } }

.dropdown {
    display: none;
    text-align: left;
    @include m {
        display: block; } }

.list {
    margin-bottom: -32px; }

.faqtop {
    margin-top: 40px;
    padding-bottom: 80px; }
