@import "../../../styles/helpers";

.section {
    position: relative;
    @include m {
        padding: 48px 0;
        border: none; } }

.row {
    display: flex;
    margin: 0 -12px;
    @include d {
        margin: 0; }
    &:first-child {
        @include m {
            display: block; } }
    &:nth-child(2) {
        @include m {
            flex-direction: column-reverse;
            .col {
                &:nth-child(2) {
                    margin-bottom: 60px; } } } }
    &:not(:last-child) {
        margin-bottom: 80px;
        @include d {
            margin-bottom: 80px; }
        @include m {
            margin-bottom: 60px; } } }

.col {
    flex: 0 0 calc(50% - 24px);
    width: calc(50% - 24px);
    margin: 0 12px;
    @include d {
        margin: 0; }
    &:first-child {
        @include d {
            flex: 1 0 auto;
            width: auto;
            padding-right: 32px; }
        @include m {
            flex: 0 0 100%;
            width: 100%;
            padding: 0; } }
    &:nth-child(2) {
        @include d {
            flex: 0 0 528px;
            width: 528px; }
        @include t {
            flex: 0 0 400px;
            width: 400px; }
        @include m {
            flex: 0 0 100%;
            width: 100%; } } }

.title {
    margin-bottom: 20px; }

.info {
    max-width: 450px;
    color: $neutrals4; }

.list {
    max-width: 260px;
    @include m {
        max-width: 100%; } }

.item {
    &:not(:last-child) {
        margin-bottom: 48px;
        @include t {
            margin-bottom: 32px; } } }

.number {
    display: inline-block;
    width: 38px;
    margin-bottom: 24px;
    border-radius: 12px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: $neutrals8;
    @include t {
        margin-bottom: 16px; }
    @include m {
        margin-bottom: 24px; } }

.content {
    @include caption-1;
    font-size: 16px;
    color: $neutrals4; }

.subtitle {
    margin-bottom: 16px;
    font-weight: 500;
    @include t {
        margin-bottom: 12px; }
    @include m {
        margin-bottom: 16px; } }

.bg {
    position: relative;
    background: $neutrals6;
    border-radius: 24px;
    @include d {
        overflow: hidden; }
    @include dark {
        background: $neutrals4; }
    img {
        width: 100%; }
    & > img {
        border-radius: 20px; } }

.preview {
    position: absolute;
    pointer-events: none;
    &:first-child {
        top: 11%;
        left: -31%;
        width: 68%; }
    &:nth-child(2) {
        top: 9.5%;
        left: 25%;
        width: 23%; } }

